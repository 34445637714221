import * as React from 'react';
import { Spinner, SpinnerProps, makeStyles } from '@fluentui/react-components';
import { Overlay } from '@fluentui/react';
export interface ISpinnerWithOverlayProps  extends SpinnerProps  {
    showSpinner: boolean;
    isDarkOverlay?: boolean;
}

const useStyles = makeStyles({
    spinnerContainer:
     {
        paddingTop: "30vh"
     },
  });

const SpinnerWithOverlay: React.FC<ISpinnerWithOverlayProps> = (props: ISpinnerWithOverlayProps) => {
    const styles = useStyles();


    if(props.showSpinner){
        return (
            <Overlay isDarkThemed={props.isDarkOverlay} >
                <div className={styles.spinnerContainer}>
                    <Spinner  {...props} />
                </div>
            </Overlay>
        );
    }else{
        return null;
    }
};
export default SpinnerWithOverlay;