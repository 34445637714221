import { Route, Routes, Navigate  } from 'react-router-dom';
import Home from '../components/home/home';
import ArchiveMail from '../components/archiveMail/archiveMail';

export const routes = (
    <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/archivehome' element={<Home />} />
        <Route path='/archiveMail' element={<ArchiveMail />}/>
        <Route path="*" element={<Navigate to="/" />} />
    </Routes >
);