

const OFFICE_ADDIN_BASE_URL = "/SI.Biz.Core.OfficeAddin/SI.Biz.Core.OfficeAddin/";
const OFFICE_ADDIN_DATA_SERVICE = "DataService/";
const OFFICE_ADDIN_OUTLOOK_SERVICE = "OutlookService/";
const CACHE_SETTINGS = "/SI.Biz.Core/SI.Biz.Core.Surface/SettingsCache/";
enum API_NAMES {
    PING = "Ping",
    GET_ARCHIVE = "GetArchivedMailInfo",
    SAVE_MAIL_IMPORT_ARCHIVE = "SaveMailInImportArchive",
    SAVE_MAIL = "SaveMailUsingNewOutlook",
    DELETE_IMPORT_ACHIVE_DOC = "DeleteTemporaryImportArchiveDocument",
    SEARCH_CASE = "SearchCaseForNewDocument",
    SEARCH_CARBONCOPY = "SearchCarbonCopyContacts",
    PREPOPULATE_CASES = "SearchCasePrepopulate",
    VALIDATE_CASE = "ValidateArchiveAndCaseForNewDocument",
    GET_VALUE = "GetValue",
    GET_PROD_NAME = "GetProductName",
    PREPOPULATE_CARBONCOPY = "SearchCarbonCopyPrepopulate"

}

export class ApiConfig {
    public static readonly checkConnection: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.PING;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly GetArchivedMailInfo: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.GET_ARCHIVE;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly SaveMailInImportArchive: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.SAVE_MAIL_IMPORT_ARCHIVE;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly SaveMail: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.SAVE_MAIL;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly DeleteImportArchiveDoc: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.DELETE_IMPORT_ACHIVE_DOC;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly SearchCase: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_DATA_SERVICE + API_NAMES.SEARCH_CASE;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly SearchCarbonCopy: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_DATA_SERVICE + API_NAMES.SEARCH_CARBONCOPY;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly validateCase: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_DATA_SERVICE + API_NAMES.VALIDATE_CASE;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly getDeafultCases: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_DATA_SERVICE + API_NAMES.PREPOPULATE_CASES;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly getValueFromSettings: string = CACHE_SETTINGS + API_NAMES.GET_VALUE;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly getProductName: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.GET_PROD_NAME;// + "?authkey=" + (storage.get("public360APIKey") as string);
    public static readonly getDefaultCarbonCopy: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_DATA_SERVICE + API_NAMES.PREPOPULATE_CARBONCOPY;// + "?authkey=" + (storage.get("public360APIKey") as string);

}

export const getFetchApiOptions = () => {
        return {
            mode: "cors",
            credentials: "include",
            redirect: "error"
        };

}

export const getEnvironment360BizURL = () => {
    return getEnvironment360URL() + "/Biz/v2/api/call";
}

export const getEnvironment360URL = () => {
    let public360Url = localStorage.getItem("public360Url");

    if(public360Url && public360Url === "" )
        public360Url = new URLSearchParams(window.location.search).get("url");

    return public360Url??"";
}
