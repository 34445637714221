import {getEnvironment360URL} from '../configs/appConfig';

export const openLink = (link: string, event?: React.MouseEvent<HTMLElement>): void => {
    link = getEnvironment360URL() + "/"+link;
    if (event) {
        event.preventDefault();
    }
    // Force to open in default browser if outlook app client is beeing used
    if ((Office.context.diagnostics.platform === Office.PlatformType.PC || Office.context.diagnostics.platform === Office.PlatformType.Mac) && Office.context.diagnostics.host === Office.HostType.Outlook && Office.context.requirements.isSetSupported('Mailbox', '1.2')) {
        Office.context.ui.openBrowserWindow(link);
    } else {
        window.open(link);
    }
};