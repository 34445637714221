import React from 'react';
import { IDocumentDetails, MAIL_STATUS } from '../../@types/models';
import SpinnerWithOverlay from '../common/controls/spinner';
import {findAndGetMailInfo} from '../../services/api/dataService';
import { makeStyles,
    useId,
    Link,
    Toaster,
    useToastController,
    ToastTitle,
    Toast,
    ToastTrigger,
    ToastIntent} from '@fluentui/react-components';

import { t } from "i18next";
import { getDocumentDetailComp, getNewMessageComponent } from './HomeComponents';

const useStyles = makeStyles({
    containerbody:
     {
        width: "100%",
        minHeight: "inherit",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
     },

  });

export const Home: React.FC = () => {
    const loadingText = t("Loading...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loadingStatus, setLoadingStatus] = React.useState(MAIL_STATUS.NOT_EXISTS);
    const [documentInfo, setDocumentInfo] = React.useState<IDocumentDetails>();
    const styles = useStyles();
    const toasterId = useId("toastIdHome");
    const { dispatchToast ,dismissToast } = useToastController(toasterId);

    const public360Url = new URLSearchParams(window.location.search).get("url");
    let docRecno = new URLSearchParams(window.location.search).get("docRecno");

    if(public360Url && public360Url !== "")
    {
        localStorage.setItem("public360Url",public360Url??"");
    }

    const processMailItem=()=>{
        docRecno = null;
        checkCaseExists();
    }

    const showToast = (message:string ,toastType:ToastIntent)=>{
        dispatchToast(
            <Toast>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Link>{t("Dismiss")}</Link>
                  </ToastTrigger>
                }
              >
                {message}
              </ToastTitle>
            </Toast>,
            { intent: toastType,timeout:5000 }
          );
    }

    const checkCaseExists = React.useCallback(() => {
        dismissToast(toasterId);
        setShowSpinner(true);
        setLoadingStatus(MAIL_STATUS.LOADING);
        try {
            findAndGetMailInfo((resData: IDocumentDetails | null) => {
                if (resData === null) {
                    setLoadingStatus(MAIL_STATUS.NOT_EXISTS);
                    setShowSpinner(false);
                } else {
                    setDocumentInfo(resData);
                    setLoadingStatus(MAIL_STATUS.EXISTS);
                    setShowSpinner(false);
                }
            }, (err: string) => {
                setErrorMsg(err);
                setLoadingStatus(MAIL_STATUS.ERROR);

            }, docRecno);
        } catch (exception) {
            setShowSpinner(false);
            if (typeof exception === 'string')
                setErrorMsg(exception);
            else if (exception instanceof Error)
                setErrorMsg(exception.message);
            setLoadingStatus(MAIL_STATUS.ERROR);
        }
    }, [dismissToast, docRecno, toasterId]);

     React.useEffect(() => {
        checkCaseExists();
        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, processMailItem);
        return () => {
            Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
        };
    },[]);

    let uiToDisplay;
    if (loadingStatus === MAIL_STATUS.ERROR) {
        setShowSpinner(false);
        showToast(errorMsg,"error");
    } else if (loadingStatus === MAIL_STATUS.EXISTS && documentInfo) {
        uiToDisplay = getDocumentDetailComp(documentInfo);
    } else if (loadingStatus === MAIL_STATUS.NOT_EXISTS) {
        uiToDisplay = getNewMessageComponent();
    }
    return (
        <div className={styles.containerbody}>
            {uiToDisplay}
             <Toaster toasterId={toasterId} />
            <SpinnerWithOverlay showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </div>
    );
};

export default Home;
