import React from 'react';
import { FluentProvider,makeStyles } from '@fluentui/react-components';
import { ThemeProvider} from './contexts/theme/ThemeProvider';
import useTheme from './contexts/theme/useTheme';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/common/Header';
import {routes} from './routes';

const useStyles = makeStyles({
     root:
     {
       minWidth:"280px",
       maxWidth:"320px",
       minHeight:"579px",
       justifyContent:"space-between"
     }
  });

const App: React.FC = () => {
    const { theme } = useTheme();
    const styles=useStyles();
  return (
    <FluentProvider theme={theme} className={styles.root}>
        <BrowserRouter>
            <Header>
                {routes}
            </Header>
        </BrowserRouter>
    </FluentProvider>
  );
};

const AppWrapper: React.FC = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
};

export default AppWrapper;
