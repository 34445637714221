import React, { useId } from 'react';
import { t } from "i18next";
import { archiveMail } from '../../services/api/dataService';
import { getEnvironment360URL } from '../../services/configs/appConfig';
import SpinnerWithOverlay from '../common/controls/spinner';
import {  useNavigate } from 'react-router-dom';
import { Button, Toast, ToastIntent, ToastTitle, ToastTrigger, Toaster, useToastController } from '@fluentui/react-components';


const ArchiveMail: React.FC = () => {
    const loadingText = t("Archiving files to 360...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const toasterId = useId();
    const { dispatchToast ,dismissToast } = useToastController(toasterId);
    const registerUrl =
    getEnvironment360URL() +
     "/locator.aspx?name=DMS.Dialog.ConnectedDocumentDialog&SAVEAS=1&MoveTo=1&UnregisteredDoc=true&dialog=modal&dialogCloseMode=WINDOWNORETURN&dialogmode=true&dropmode=single&dialogFromAddin=true&_navigationtarget=self&recno=";

    const navigate = useNavigate();
    const showToast = (message:string ,toastType:ToastIntent)=>{
        dispatchToast(
            <Toast>
              <ToastTitle
                action={
                  <ToastTrigger>
                       <Button onClick={archiveTheMail}>{t("Retry")}</Button>
                  </ToastTrigger>
                }
              >
                {message}
              </ToastTitle>
            </Toast>,
            { intent: toastType }
          );
    }

   const archiveTheMail  = ()=>{
    dismissToast(toasterId);
    setShowSpinner(true);
    let checkWindowClosed: NodeJS.Timeout;
    try {
        archiveMail((resData: string | null) => {
            if (resData === null) {
                showToast(t("Recno not generated"),"error");
                setShowSpinner(false);
            } else {
                Office.context.ui.displayDialogAsync(registerUrl + resData, {height: 70, width: 52, promptBeforeOpen: false},
                    function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        setShowSpinner(false);
                        showToast(t("Failed to open dialog: ") + asyncResult.error.message ,"error");
                    } else {
                        const dialog = asyncResult.value;

                        // Add event handler for when the dialog is closed
                        dialog.addEventHandler(Office.EventType.DialogEventReceived,  ()=> {
                            onDialogClosed(resData);
                        });
                    }
                    }
                );
        }
    }, (err: string) => {
        setShowSpinner(false);
         showToast(err,"error");
        });
    }
    catch (exception) {
        setShowSpinner(false);
        showToast(exception as string,"error");
    }

    return () => clearInterval(checkWindowClosed);
   }

    React.useEffect(() => {
        archiveTheMail();
    },[]);

    function onDialogClosed(docRecno: string) {
        setShowSpinner(false);
        navigate(`/archivehome?docRecno=${docRecno}`);
    }

    return (
        <>
            <Toaster toasterId={toasterId} />
            <SpinnerWithOverlay showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </>
    );
}

export default ArchiveMail;
