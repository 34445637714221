import React from 'react';
import { MailWithQuestion } from '@si/fib-icons';
import { Button,makeStyles} from '@fluentui/react-components';
import {
    Stack,
  } from "@fluentui/react";
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    ctnButton:
     {
        borderRadius: "4px",
        background: "#0F6CBD",
        color: 'white'
     },
     archiveBody:
     {
        padding: "24px",
        justifyContent:"flex-start"

     },
     archiveHeading:{
        leadingTrim: "both",
        textEdge: "cap",
        fontStyle: "normal",
        fontFamily: "Segoe UI",
        fontSize:"20px",
        fontWeight:600,
        lineHeight:"normal",
        textAlign:'center',
        display: "flex",
        alignItems:"center",
        gap: "8px",
        height: "24px"
     }

  });

const ArchiveHome: React.FC = () => {
    const styles = useStyles();
    const navigate = useNavigate();

    return (
            <Stack className={styles.archiveBody}>
            <div className={styles.archiveHeading}>
                <MailWithQuestion size={24} />
                <span>&nbsp;{t('Not archived in 360°')}</span>
            </div>
                <p>{t('The selected email is currently not archived in 360°, click the button below to continue.')}</p>
                <Button className={styles.ctnButton} onClick={()=> navigate('/archiveMail')}>{t('Save to 360')}</Button>
            </Stack>
    );
}

export default ArchiveHome;
