import { IDocumentDetails } from "../../@types/models";
import ArchiveDetail from "../archiveDetail/archiveDetail";
import ArchiveHome from "../archiveHome/archiveHome";

export const getNewMessageComponent = (): JSX.Element => {
    return (
        <ArchiveHome/>
    );
};
export const getDocumentDetailComp = (documentInfo: IDocumentDetails): JSX.Element => {
    return (
        <ArchiveDetail
            AccessCodeDesc={documentInfo.AccessCodeDesc}
            CaseDescription={documentInfo.CaseDescription}
            ArchiveCode={documentInfo.ArchiveCode}
            CaseNumber={documentInfo.CaseNumber}
            CategoryCode={documentInfo.CategoryCode}
            DocumentDetailsLink={documentInfo.DocumentDetailsLink}
            DocumentTitle={documentInfo.DocumentTitle}
            Documentnumber={documentInfo.Documentnumber}
            OrgUnitName={documentInfo.OrgUnitName}
            Paragraph={documentInfo.Paragraph}
            ResponsibleName={documentInfo.ResponsibleName}
        />
    );
};